import React from 'react'
import {
  Box,
  Card,
  Image,
  Heading,
  Text
} from 'rebass'

function App() {
  return (
    <Box width={800}>
      <Card width={240} sx={{
        p: 1,
        margin: 'auto',
        borderRadius: 2,
        boxShadow: '0 0 16px rgba(0, 0, 0, .25)',
      }}>
        <Box px={2}>
          <Heading as = 'h3'>
            Leo's Blog
          </Heading>
          <Text fontSize={0}>
            Lorem Ipsum Dipsum Dolor Sit Amet
          </Text>
        </Box>
      </Card>
    </Box>
  );
}

export default App;
